import React from "react"
import ContactUs from "../components/ContactUs"
import {Team,senior_team,full_team} from '../components/TeamBox';

import Layout from "../components/layout";
import PageHeader from "../components/PageHeader";

class SecondPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      compact: false,
      showModal: false,
      modalNo: false,
      activeModal: "",
    }
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggle(action) {
    this.setState({
      compact: !!action,
    })
  }
  handleOpenModal (val) {
   this.setState({ activeModal: val });
   this.setState({ showModal: true });
  }
  
  
  handleCloseModal () {
      this.setState({ showModal: false });
      this.setState({ activeModal: "" });
  }

  render() {
    return (
      <Layout>
        <PageHeader title="Meet Our Team" subtitle="Discuss your ideas by communicating with us" />
        <section className="section-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mobile-margin-top">
                <div className="col-limit-3">
                  <div className="heading-top"></div>
                  <h2 className="heading-2 mb-5">
                    Leadership Team
                  </h2>
                </div>
              </div>
            </div>
            <div className={`main-media-team compact`}>
              {senior_team.map((o,i)=>(
              <Team 
              handleOpenModal = {this.handleOpenModal}
              handleCloseModal = {this.handleCloseModal}
              showModal = {this.state.showModal}
              activeModal = {this.state.activeModal}
              modalNo={this.state.modalNo}
              index={i}
              key={i}  {...o} />))}
            </div>
            <div className={`main-media-team2 compact`}>
              {full_team.map((o,i)=>(
                <Team 
                handleOpenModal = {this.handleOpenModal}
                handleCloseModal = {this.handleCloseModal}
                showModal = {this.state.showModal}
                activeModal = {this.state.activeModal}
                modalNo={this.state.modalNo}
                index={i}
                key={i} {...o} />))}
              </div>
          </div>
        </section>
        <ContactUs />
      </Layout>
    )
  }
}

export default SecondPage

