import React  from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getFileName } from "../utils"
import { LinkedIn } from "./Social"
import ReactModal from 'react-modal';

export const Team = ({
  imgSrc = "media_placeholder.jpg",
  heading = "-",
  description = "-",
  bio = "-",
  link = {},
  modalNo,
  modalId,
  index,
  showModal,
  activeModal,
  handleOpenModal,
  handleCloseModal
}) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1920) {
              originalImg
            }
          }
        }
      }
    }
  `)

  data.allImageSharp.edges.forEach(element => {
    if (getFileName(element.node.fluid.originalImg) === imgSrc) {
      imgSrc = element.node.fluid.originalImg
    }
  })
  return (
    <div className="media-box-team">
      <div className="main-img-team" style={{ backgroundImage: `url(${imgSrc})` }}>

      </div>
      <div className="content">
          <div className="links">
            <a className="box-label line-below" onClick={(e) => handleOpenModal(modalId)}>
              {heading}
            </a>
          </div>
          <p>{description}</p>
          
          <ReactModal 
            isOpen={showModal && activeModal === modalId}
            contentLabel="{heading}"
            className="teamModal"
            overlayClassName="teamModalOverlay"
            closeTimeoutMS={500}
          >
            <div className="teamBio">
              <h5>{heading} - {description}</h5>
              <p dangerouslySetInnerHTML={{__html: bio}} />
            </div>
            <div className="btn-bio">
              <button className="btn btn-primary" size="sm" onClick={handleCloseModal}>Close</button>
            </div>
            
           </ReactModal>
          <ul className="social-link">
            <li>
              <a href={link} target="_blank">
                <LinkedIn />
              </a>
              </li>
          </ul>

          
      </div>
    </div>
  )
}

export default Team
export const senior_team = [
  {
    heading: "Javier Garcia Gomez",
    description:
        "Chief Executive Officer (CEO)",
    imgSrc: "javier-black.jpg",
    link: "https://www.linkedin.com/in/javiergg/",
    bio: "<ul><li>Javier is the Chief Executive Officer at VoerEir AB and is set to take the company to next level of growth.</li>"+
    "<li>He has more than <strong>20 years</strong> of experience working in ICT sector, where he has held different executive positions"+
    "in sales, technology leadership, product management and network engineering at <strong>Ericsson, Alcatel-Lucent and Nortel</strong>.</li>"+
    "<li>Javier has ample international experience working in several countries like Spain, Sweden, India, France, and USA.</li>"+
    "<li>He trusts in the technology positive transforming power for our society.</li></ul>",
    modalId: "1",
  },
  {
    heading: "Carlos Garcia Braschi",
    description:
        "Chief Technology officer (CTO)",
    link: "https://www.linkedin.com/in/carlosgarciabraschi/",
    imgSrc: "carlos_black.png",
    bio: "<ul><li>Carlos defines the company technology and product strategy and supports our customers outlining their cloud evolution.</li>" +
    "<li>He has over <strong>30 years</strong> of experience designing and developing Telecommunications networks.</li>"+
    "<li>Former <strong>NFVI Manager at Telefonica Spain.</strong></li>"+
    "<li>Expert in Telco Cloud, NFVI and Telecom Networks.</li></ul>",
    modalId: "2",
  },

]
export const full_team = [
  {
    heading: "Amit Kumar Prasad",
    description: "Head of Architecture",
    link: "https://www.linkedin.com/in/amit-kumar-prasad-46300a26/",
    imgSrc: "amit-black.jpg",
    bio: "<ul><li>Amit is a Senior Cloud Architect in charge of defining VoerEir product and solution architecture.He is a Kubernetes expert.</li>"+
    "<li>He has more than <strong>14 years</strong> of experience in architecting and designing Cloud, DevOps and software solutions.</li>"+
     "<li>Led the DevOps and Cloud transformation journey at <strong>TSYS</strong>. Worked as Solutions"+
     "architect in <strong>NTT Data</strong> and <strong>Dell</strong>.</li>"+
     "<li>Passionate about cloud technology, innovation and transforming the digital ecosystem.</li></ul>",
    modalId: "4",
  },
  {
    heading: "Deepanshu Bhatia",
    description: "Head of Development",
    link: "https://www.linkedin.com/in/deep23bhatia/",
    imgSrc: "deepanshu-black.jpg",
    bio: "<ul><li>Deepanshu is in charge of Touchstone product research and development at VoerEir.</li>"+
    "<li>He has more than <strong>6 years</strong> of experience developing and testing NFVI and solving complexities."+
    "Former full stack developer at <strong>Snapdeal</strong>.</li>"+
    "<li>Expert in building efficient and scalable systems with OpenStack and Kubernetes.</li>"+
    "<li>Enjoys mentoring and teaching people to help them improving their professional career</li></ul>",
    modalId: "5",
  },
  {
    heading: "Md Safiyat Reza",
    description: "Head of Operations",
    link: "https://www.linkedin.com/in/rezasafiyat/",
    imgSrc: "safiyat-black.jpg",
    bio: "<ul><li>Safiyat manages our technical operations with customers and partners: proof of concepts, trials and commercial deployments. </li>"+
    "<li>He has more than <strong>6 years</strong> of experience working in Cloud Computing and NFVI.</li>"+
    "<li>Expert cloud engineer and programmer. Active contributor to many open-source projects like <strong>OpenStack, OPNFV</strong>, and <strong>Python</strong> Foundation.</li>"+
    "<li>Enthusiastic about technology, music, and history.</li></ul>",
    modalId: "6",
  },
  {
    heading: "Shivani Devshali",
    description: "Head of Quality & People",
    link: "https://www.linkedin.com/in/shivani-devshali-b2b6a359/",
    imgSrc: "shivani-black.jpg",
    bio: "<ul><li>Shivani manages VoerEir scrum teams, development process execution and roadmap and project quality measurement. </li>"+
    " <li>She has more than <strong>7 years</strong> of experience in software development in Cloud Computing and OpenStack. Former full stack developer at <strong>Accenture</strong>.</li>"+
    "<li>Expert in Agile project management and product delivery.</li>"+
    "<li>Loves building fantastic teams that work hard and play harder.</li></ul>",
    modalId: "7",
  },
      
]
